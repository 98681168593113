<template>
  <div class="manage-subjects">
    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>
      <!-- <AddButton
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="items"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Data'"
                :append-icon="''"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
      </v-data-table>

      <floating-button
        :create-function="openDialog"
        :icon="'mdi-plus'"
        :title="''"
      />
    </v-card>

    <SubjectDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :multiPapers="multiPapers"
      :subjectLevels="subjectLevels"
      :examSits="examSits"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import SubjectDialog from "./Forms/SubjectDialog.vue";
import { get, create, update, remove } from "./services";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    SubjectDialog,
  },
  name: "Subject",
  data() {
    return {
      title: "Manage Subjects",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      item: null,
      items: [],
      subjectLevels: [],
      multiPapers: [],
      examSits: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Abbrev",
          align: "start",
          sortable: false,
          value: "abbreviation",
          class: "primary--text",
        },
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
          class: "primary--text",
        },

        {
          text: "is-Default",
          align: "start",
          sortable: false,
          value: "default",
          class: "primary--text",
        },
        {
          text: "Multi Papers",
          align: "start",
          sortable: false,
          value: "multi_papers.name",
          class: "primary--text",
        },
        {
          text: "Level",
          align: "start",
          sortable: false,
          value: "level.name",
          class: "primary--text",
        },
        {
          text: "Sits",
          align: "start",
          sortable: false,
          value: "sits.name",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],

      search: null,
    };
  },

  computed: {
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
  },

  methods: {
    init(params) {
      this.loading = true;
      get({
        ...params,
      })
        .then((response) => {
          // console.log('resp', response.data[0].total)
          this.params.total = response.data[0].total;
          this.loading = false;
          this.items = response.data[0]
            ? response.data[0].data
                .map((option) => ({
                  ...option,
                  isDefault: option.is_default === true ? true : false,
                  default: option.is_default === false ? "No" : "Yes",
                }))
                .sort(function(a, b) {
                  if (a > b) return 1;
                  return -1;
                })
                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchOptions(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },
    searchRoles(data) {
      this.searchOptions(data);
    },
    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            console.log("created");
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },
    getSubjectLevels() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "SUBJECT_LEVELS")
        .then((response) => {
          this.subjectLevels = response;
        });
    },
    getSubjectPapers() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "HAS_PAPER_2")
        .then((response) => {
          this.multiPapers = response;
        });
    },
    getExamSits() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "EXAM_SITS")
        .then((response) => {
          this.examSits = response;
        });
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getSubjectPapers();
    this.getSubjectLevels();
    this.getExamSits();
  },
};
</script>

<style scoped></style>
