<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Subject</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Add Name"
                    v-model="formData.name"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Subject code"
                    v-model="formData.code"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Subject Abbrev"
                    v-model="formData.abbreviation"
                    required
                    :rules="isValid.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Subject Multi Papers"
                    v-model="formData.multi_papers"
                    :items="multiPapers"
                    :item-text="'name'"
                    return-object
                    required
                    :rules="isValid.name"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Subject Level"
                    v-model="formData.level"
                    :items="subjectLevels"
                    :item-text="'name'"
                    return-object
                    required
                    :rules="isValid.name"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    label="Exam Sits"
                    v-model="formData.sits"
                    :items="examSits"
                    :item-text="'name'"
                    return-object
                    required
                    :rules="isValid.name"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-container class="px-0" fluid>
                    <v-checkbox
                      v-model="formData.isDefault"
                      :label="`Check this to make subject default`"
                    ></v-checkbox>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
        <!--<pre>{{ formData }}</pre>-->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    closeDialog: { type: Function, required: true },
    dialog: { type: Boolean, required: true },
    isEditing: { type: Boolean },
    formData: { type: Object },
    save: { type: Function },
    loading: { type: Boolean },
    subjectLevels: { type: Array },
    multiPapers: { type: Array },
    examSits: { type: Array },
  },
  name: "SubjectDialog",
  data: () => ({
    valid: true,
    isValid: {
      name: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };
      data.code = data.code
        ? data.code
        : data.name
            .trim()
            .split(" ")
            .join("_")
            .toUpperCase();

      this.save(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
  },
  mounted() {},
};
</script>
